import { useState } from 'react';
import { Box, Input, InputAdornment, TextField } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../assets/global/SearchIcon.svg';
import { ReactComponent as GreenDownArrow } from '../../assets/global/GreenDownArrow.svg';
import { RedHatTypo } from '../../StyledComponents/Typographies';

import BTC from '../../assets/Exchange/BTC.png';
import SHIB from '../../assets/Exchange/SHIB.png';
import USDT from '../../assets/Exchange/USDT.png';

import Green from '../../assets/Exchange/GreenUpArrow.svg';
import Red from '../../assets/Exchange/RedDownArrow.svg';

const TokenList = () => {
  const TokenSymbolLists = ['USDT', 'USDT', 'WRX', 'BTC'];

  const TokenExchangeList = [
    {
      id: 1,
      tokenName: 'USDT',
      percentage: '0.18',
      profit: true,
      value: 79.58,
      icon: USDT,
    },
    {
      id: 2,
      tokenName: 'BTC',
      percentage: '2.35',
      profit: true,
      value: 3377990,
      icon: BTC,
    },
    {
      id: 3,
      tokenName: 'SHIB',
      percentage: '0.09',
      profit: false,
      value: 0.002,
      icon: SHIB,
    },
  ];

  const [SelectedTokenIndex, setSelectedTokenIndex] = useState(0);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '25% 75%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          background: 'rgba(0, 0, 0, 0.8)',
          borderTopLeftRadius: '8px',
        }}
      >
        {TokenSymbolLists.map((item, index) => (
          <Box
            key={index}
            sx={{
              py: 2,
              display: 'grid',
              placeItems: 'center',
              color: 'white',
              borderBottom: '1px solid #525252',
              borderLeft:
                index === SelectedTokenIndex ? '8px solid #FCD86B' : '',
              cursor: 'pointer',
              borderTopLeftRadius: index === 0 ? '8px' : 0,
              background: index === SelectedTokenIndex ? '#343434' : '',
            }}
            onClick={() => setSelectedTokenIndex(index)}
          >
            {index === SelectedTokenIndex && (
              <Box
                sx={{
                  height: '100%',
                  background: '#FCD86B',
                  width: '100%',
                }}
              ></Box>
            )}
            <RedHatTypo sx={{ width: '80%' }} noWrap>
              {item}
            </RedHatTypo>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          background: '#343434',
          borderTopRightRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          py: 1,
        }}
      >
        <TextField
          sx={{
            width: '80%',
            alignSelf: 'center',
            background: '#4F4F4F',
            border: '1px solid #BEBEBE',
            borderRadius: '8px',
          }}
          InputProps={{
            style: { color: '#BEBEBE', borderRadius: '8px' },
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          size='small'
          placeholder='Search'
        ></TextField>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '25% 25% 50%',
            color: '#BEBEBE',
            placeItems: 'center',
            pb: 1,
            pt: 2,
            borderBottom: '1px solid #525252',
          }}
        >
          <RedHatTypo>Pair</RedHatTypo>
          <RedHatTypo sx={{ color: '#66C37B' }}>
            Vol <GreenDownArrow />
          </RedHatTypo>
          <RedHatTypo>Change</RedHatTypo>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', py: 1 }}>
          {TokenExchangeList.map((item) => (
            <Box
              key={item.id}
              sx={{
                display: 'grid',
                gridTemplateColumns: '60% 40%',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 1,
                borderBottom: '1px solid #525252',
                color: 'white',
              }}
            >
              <Box sx={{ m: 0, display: 'flex', gap: 1 }}>
                <img
                  src={item.icon}
                  alt={item.tokenName}
                  style={{ height: 32, width: 32, objectFit: 'contain' }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <RedHatTypo sx={{ fontSize: 13, fontWeight: 700 }}>
                      {item.tokenName}
                    </RedHatTypo>
                    <RedHatTypo
                      sx={{ fontSize: 12, fontWeight: 700, color: '#BEBEBE' }}
                    >
                      /SGD
                    </RedHatTypo>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <img
                      src={item.profit ? Green : Red}
                      alt='arrowIcon'
                      height='16'
                      width='16'
                    />
                    <RedHatTypo
                      sx={{
                        color: item.profit ? '#66C37B' : '#F6685E',
                        fontWeight: 700,
                        fontSize: 13,
                      }}
                    >
                      {item.percentage} %
                    </RedHatTypo>
                  </Box>
                </Box>
              </Box>

              <RedHatTypo
                sx={{
                  fontSize: 12,
                  fontWeight: 500,
                  alignSelf: 'center',
                  textAlign: 'end',
                }}
              >
                {item.value.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
              </RedHatTypo>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default TokenList;
