export const BuyPriceDetails = [
  {
    id: 1,
    volume: 0.15989,
    buyPrice: 3378144,
    percentage: Math.floor(Math.random() * 9 + 1) * 10,
  },
  {
    id: 2,
    volume: 0.45989,
    buyPrice: 3378094,
    percentage: Math.floor(Math.random() * 9 + 1) * 10,
  },
  {
    id: 3,
    volume: 0.45992,
    buyPrice: 3377679,
    percentage: Math.floor(Math.random() * 9 + 1) * 10,
  },
  {
    id: 4,
    volume: 0.46,
    buyPrice: 3376981,
    percentage: Math.floor(Math.random() * 9 + 1) * 10,
  },
  {
    id: 5,
    volume: 0.48954,
    buyPrice: 3376326,
    percentage: Math.floor(Math.random() * 9 + 1) * 10,
  },
  {
    id: 6,
    volume: 0.49015,
    buyPrice: 3374802,
    percentage: Math.floor(Math.random() * 9 + 1) * 10,
  },
  {
    id: 7,
    volume: 0.49349,
    buyPrice: 3374465,
    percentage: Math.floor(Math.random() * 9 + 1) * 10,
  },
  {
    id: 8,
    volume: 0.49369,
    buyPrice: 3370153,
    percentage: Math.floor(Math.random() * 9 + 1) * 10,
  },
  {
    id: 9,
    volume: 0.49372,
    buyPrice: 3370000,
    percentage: Math.floor(Math.random() * 9 + 1) * 10,
  },
];

export const SellPriceDetails = [
  {
    id: 1,
    sellPrice: 3383749,
    volume: 0.00727,
    percentage: Math.floor(Math.random() * 9 + 1) * 10,
  },
  {
    id: 2,
    sellPrice: 3383998,
    volume: 0.15507,
    percentage: Math.floor(Math.random() * 9 + 1) * 10,
  },
  {
    id: 3,
    sellPrice: 3383999,
    volume: 0.20766,
    percentage: Math.floor(Math.random() * 9 + 1) * 10,
  },
  {
    id: 4,
    sellPrice: 3384000,
    volume: 0.22825,
    percentage: Math.floor(Math.random() * 9 + 1) * 10,
  },
  {
    id: 5,
    sellPrice: 3385000,
    volume: 0.22966,
    percentage: Math.floor(Math.random() * 9 + 1) * 10,
  },
  {
    id: 6,
    sellPrice: 3386370,
    volume: 0.23012,
    percentage: Math.floor(Math.random() * 9 + 1) * 10,
  },
  {
    id: 7,
    sellPrice: 3387269,
    volume: 0.23154,
    percentage: Math.floor(Math.random() * 9 + 1) * 10,
  },
  {
    id: 8,
    sellPrice: 3387320,
    volume: 0.23156,
    percentage: Math.floor(Math.random() * 9 + 1) * 10,
  },
  {
    id: 9,
    sellPrice: 3387377,
    volume: 0.23199,
    percentage: Math.floor(Math.random() * 9 + 1) * 10,
  },
];
