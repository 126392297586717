import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { Box } from '@mui/material';
import P2pOptions from '../Components/P2P/P2pOptions';
import img from '../assets/global/bg1.png';
import P2pContent from '../Components/P2P/P2pContent';

const P2P = () => {
  return (
    <Header>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '250px auto',
          width: '100%',
          minHeight: '90vh',
          backgroundImage: `url(${img})`,
          backgroundSize: 'cover',
          overflowX: 'auto',
        }}
      >
        <Box
          sx={{
            background: 'rgba(152, 152, 152, 0.25)',
          }}
        >
          <P2pOptions />
        </Box>

        <P2pContent />
      </Box>
      <Footer />
    </Header>
  );
};

export default P2P;
