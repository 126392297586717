import React from 'react';
import Bank from '../../assets/LandingPage/Bank.svg';
import Crypto from '../../assets/LandingPage/Crypto.svg';
import Verified from '../../assets/LandingPage/Verified.svg';
import Wallet from '../../assets/LandingPage/Wallet.svg';
import BitcoinSpace from '../../assets/LandingPage/BitcoinSpace.png';
import { Box } from '@mui/material';
import { RedHatTypo } from '../../StyledComponents/Typographies';

const Section3_Data = [
  {
    id: 1,
    icon: Wallet,
    title: 'Dedicated Wallet',
    data: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet turpis sed magna malesuada ultricies ac ut ante. Aenean bibendum mi eu condimentum.',
  },
  {
    id: 2,
    icon: Crypto,
    title: 'Trade Cryptos',
    data: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet turpis sed magna malesuada ultricies ac ut ante. Aenean bibendum mi eu condimentum.',
  },
  {
    id: 3,
    icon: Verified,
    title: 'Verified Users',
    data: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet turpis sed magna malesuada ultricies ac ut ante. Aenean bibendum mi eu condimentum.',
  },
  {
    id: 4,
    icon: Bank,
    title: 'Trusted Platform',
    data: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet turpis sed magna malesuada ultricies ac ut ante. Aenean bibendum mi eu condimentum.',
  },
];

const LandingPageSection3 = () => {
  return (
    <Box
      sx={{
        width: '90%',
        display: 'grid',
        gridTemplateColumns: { xs: '100%', md: '50% 50%' },
        py: { xs: 0, md: 8 },
        pb: 6,
        color: 'white',
        placeItems: 'center',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        {Section3_Data.map((item) => (
          <Box
            key={item.id}
            sx={{
              width: '100%',
              background:
                'linear-gradient(135deg, #292309 0%, rgba(41, 35, 9, 0.47) 100%)',
              display: 'flex',
              alignItems: 'center',
              py: 2,
            }}
          >
            <Box
              sx={{
                height: { xs: 24, md: 45 },
                width: { xs: 24, md: 45 },
                background:
                  'linear-gradient(135deg, #292309 0%, rgba(41, 35, 9, 0.47) 100%)',
                boxShadow:
                  '2px 4px 4px 0px rgba(255, 255, 255, 0.13) inset, -2px -3px 4px 0px rgba(255, 255, 255, 0.10) inset',
                padding: { xs: '15px', md: '20px' },
                borderRadius: '50%',
                marginLeft: { xs: '10px', md: '25px' },
              }}
            >
              <img
                src={item.icon}
                alt={item.title + '_img'}
                style={{
                  height: 'inherit',
                  width: 'inherit',
                }}
              />
            </Box>

            <Box sx={{ pr: { xs: 2, md: 4 }, pl: 2 }}>
              <RedHatTypo
                sx={{
                  fontSize: { xs: 18, md: 28, lg: 30 },
                  color: '#FCD535',
                  fontWeight: 500,
                }}
              >
                {item.title}
              </RedHatTypo>
              <RedHatTypo sx={{ fontSize: { xs: 12, md: 14, lg: 16 } }}>
                {item.data}
              </RedHatTypo>
            </Box>
          </Box>
        ))}
      </Box>

      <Box
        sx={{
          width: '100%',
          height: { xs: '300px', md: 'auto' },
          gridRow: { xs: 1, md: 'auto' },
        }}
      >
        <img
          src={BitcoinSpace}
          alt='bitcoin'
          style={{ width: 'inherit', objectFit: 'contain', height: 'inherit' }}
        />
      </Box>
    </Box>
  );
};

export default LandingPageSection3;
