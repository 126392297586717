import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { data } from './ChartData';
import { Box } from '@mui/material';

const Chart = () => {
  return (
    <Box sx={{ height: '100%' }}>
      <ReactApexChart
        options={data.options}
        series={data.series}
        type='candlestick'
        height={'100%'}
        className='chart'
      />
    </Box>
  );
};

export default Chart;
