import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  List,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import React from 'react';
import { ReactComponent as DownArrow } from '../../assets/P2P/WhiteTriangleDownArrow.svg';
import { RedHatTypo } from '../../StyledComponents/Typographies';
import P2pSortOptions from './P2pSortOptions';

const PaymentOptions = [
  { id: 1, optionName: 'Payment1' },
  { id: 2, optionName: 'Payment2' },
];
const RegionOptions = [
  { id: 1, optionName: 'Region1' },
  { id: 2, optionName: 'Region2' },
];

const P2pOptions = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        color: 'white',
        backdropFilter: 'blur(7.5px)',
      }}
    >
      <Box
        sx={{
          px: 3,
          py: 2,
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '1px solid rgba(255, 255, 255, 0.6)',
        }}
      >
        <RedHatTypo sx={{ fontWeight: 700 }}>Filter</RedHatTypo>
        <RedHatTypo>Clear</RedHatTypo>
      </Box>

      <Box>
        <P2pSortOptions list={PaymentOptions}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              py: 2,
              px: 3,
              userSelect: 'none',
              borderBottom: '1px solid rgba(255, 255, 255, 0.6)',
            }}
          >
            <RedHatTypo>Payment</RedHatTypo>
            <DownArrow />
          </Box>
        </P2pSortOptions>
        <P2pSortOptions list={RegionOptions}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              py: 2,
              px: 3,
              userSelect: 'none',
              borderBottom: '1px solid rgba(255, 255, 255, 0.6)',
            }}
          >
            <RedHatTypo>Regions</RedHatTypo>
            <DownArrow />
          </Box>
        </P2pSortOptions>
      </Box>
    </Box>
  );
};

export default P2pOptions;
