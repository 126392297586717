import { Box, Button } from '@mui/material';
import React from 'react';
import { RedHatTypo } from '../../StyledComponents/Typographies';
import SecurityIcon from '../../assets/LandingPage/Security.svg';
import UpDownArrowIcon from '../../assets/LandingPage/UpDownArrow.svg';
import BoltIcon from '../../assets/LandingPage/Bolt.svg';
import FastForwardIcon from '../../assets/LandingPage/FastForward.svg';

const Section2_Data = [
  {
    id: 1,
    icon: SecurityIcon,
    title: 'Highly Secure',
    data: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet turpis sed magna malesuada ultricies ac ut ante.',
  },
  {
    id: 2,
    icon: BoltIcon,
    title: 'Quick',
    data: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet turpis sed magna malesuada ultricies ac ut ante.',
  },
  {
    id: 3,
    icon: UpDownArrowIcon,
    title: 'Asset transaction',
    data: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet turpis sed magna malesuada ultricies ac ut ante.',
  },
  {
    id: 4,
    icon: FastForwardIcon,
    title: 'Fast Platform',
    data: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet turpis sed magna malesuada ultricies ac ut ante.',
  },
];

const LandingPageSection2 = () => {
  return (
    <Box
      sx={{
        color: 'white',
        width: '90%',
        display: 'grid',
        gridTemplateColumns: { xs: '100%', md: '40% 60%' },
        pb: { xs: 6, lg: 8 },
        py: { xs: 2, lg: 6 },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: '32px', md: '56px' },
          width: 'inherit',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        <Box>
          <RedHatTypo
            sx={{
              color: '#FFDE33',
              fontSize: { xs: 28, md: 42 },
              fontWeight: 600,
              lineHeight: 1.3,
            }}
          >
            Our Solution to Crypto needs & trading
          </RedHatTypo>
          <RedHatTypo sx={{ mt: 1, fontSize: { xs: 13, md: 16 } }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit
            amet turpis sed magna malesuada ultricies ac ut ante.
          </RedHatTypo>
        </Box>
        <Button
          sx={{ borderRadius: '20px', color: '#BB4A2D', fontWeight: 600 }}
          variant='contained'
        >
          Get Started
        </Button>
      </Box>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: 'auto', sm: '50% 50%' },
          gridTemplateRows: { xs: 'auto', sm: 'auto auto' },
          py: 4,
        }}
      >
        {Section2_Data.map((item) => (
          <Box
            key={item.id}
            sx={{
              mt: { xs: 4, lg: item.id % 2 === 0 ? 10 : 0 },
              display: 'flex',
              flexDirection: 'column',
              gap: { xs: '12px', lg: '24px' },
              width: '90%',
            }}
          >
            <img src={item.icon} alt={item.title} height='48' width='48' />
            <RedHatTypo
              sx={{
                fontSize: { xs: 24, md: '36px' },
                fontWeight: 500,
                lineHeight: 1,
              }}
            >
              {item.title}
            </RedHatTypo>
            <RedHatTypo sx={{ fontSize: { xs: 13, md: 16 } }}>
              {item.data}
            </RedHatTypo>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default LandingPageSection2;
