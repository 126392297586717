import './App.css';
import React from 'react';
import RoutesConfig from './Routes/RoutesConfig';
import Layout from './Components/Layout';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './Theme/Index';

const App: React.FC<any> = () => {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <RoutesConfig />
      </Layout>
    </ThemeProvider>
  );
};

export default App;
