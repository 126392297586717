import React from 'react';
import Header from '../Components/Header';
import { Box } from '@mui/material';
import TokenList from '../Components/Exchanges/TokenList';
import Chart from '../Components/Exchanges/Chart';
import ExchangeList from '../Components/Exchanges/ExchangeList';
import Footer from '../Components/Footer';

const Exchanges = () => {
  return (
    <Header>
      <Box
        sx={{
          background: 'black',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'grid',
            gridTemplateColumns: '300px auto',
            background: 'rgba(0, 0, 0, 0.46)',
            overflowY: 'hidden',
          }}
        >
          <Box>
            <TokenList />
          </Box>
          <Box
            sx={{
              display: 'grid',
              gridTemplateRows: '350px auto',
              overflowX: 'auto',
              minWidth: 750,
              overflowY: 'hidden',
              // height: 'auto',
            }}
          >
            <Chart />
            <ExchangeList />
          </Box>
        </Box>
        <Footer />
      </Box>
    </Header>
  );
};

export default Exchanges;
