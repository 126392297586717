import WhatsappIcon from '../assets/Footer/Whatsapp.svg';
import FacebookIcon from '../assets/Footer/Facebook.svg';
import TwitterIcon from '../assets/Footer/Twitter.svg';

export interface FooterLinksSchema {
  name: string;
  link: string;
  icon?: string;
}

export const FooterLinks = [
  {
    id: 1,
    title: 'About',
    data: [
      {
        name: 'Blog',
        link: '#',
      },
      {
        name: 'Careers',
        link: '#',
      },
      {
        name: 'Announcements',
        link: '#',
      },
      {
        name: 'Assets',
        link: '#',
      },
    ],
  },
  {
    id: 2,
    title: 'Services',
    data: [
      {
        name: 'Account',
        link: '#',
      },
      {
        name: 'Partnership',
        link: '#',
      },
      {
        name: 'Referral',
        link: '#',
      },
      {
        name: 'API',
        link: '#',
      },
    ],
  },
  {
    id: 3,
    title: 'Support',
    data: [
      {
        name: 'Help center',
        link: '#',
      },
      {
        name: 'Security',
        link: '#',
      },
      {
        name: 'FAQ',
        link: '#',
      },
      {
        name: 'Online Chat',
        link: '#',
      },
    ],
  },
  {
    id: 4,
    title: 'Socials',
    data: [
      {
        name: 'Facebook',
        link: '#',
        icon: FacebookIcon,
      },
      {
        name: 'WhatsApp',
        link: '#',
        icon: WhatsappIcon,
      },
      {
        name: 'Twitter',
        link: '#',
        icon: TwitterIcon,
      },
    ],
  },
];
