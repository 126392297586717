import {
  Box,
  Button,
  MenuItem,
  TextField,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import React, { useState } from 'react';
import { RedHatTypo } from '../../StyledComponents/Typographies';
import { ReactComponent as DownArrow } from '../../assets/global/DownTriangleArrow.svg';
import { ReactComponent as Wallet } from '../../assets/global/WalletIcon.svg';
import { BuyPriceDetails, SellPriceDetails } from './ExchangeListData';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const Options = [
  { id: 1, name: 'Market Depth' },
  { id: 2, name: 'Order Volume' },
];

const currencies = [
  {
    value: 'Limit',
    label: 'Limit',
  },
  {
    value: 'No Limit',
    label: 'No Limit',
  },
];

const ExchangeList = () => {
  const [SelectedOptions, SetSelectedOptions] = useState(1);

  const [selectedTwoOptions, setSelectedTwoOptions] = useState(1);

  const TwoOptions = [
    { id: 1, name: 'Buy' },
    { id: 2, name: 'Sell' },
  ];

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: 'auto auto', md: '60% 40%' },
        // height: '100%',
        // overflowY: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '60px auto',
            sm: '70px auto',
            md: '80px auto',
          },
        }}
      >
        <Box
          sx={{
            // height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            background: 'rgba(0, 0, 0, 0.8)',
          }}
        >
          {Options.map((item) => (
            <Box
              key={item.id}
              sx={{
                textAlign: 'center',
                cursor: 'pointer',
                color: 'white',
                backgroundColor:
                  SelectedOptions === item.id ? '#343434' : 'transparent',
                borderBottom: '1px solid #525252',
                display: 'flex',
                justifyContent: 'space-evenly',
                borderTopLeftRadius: '10px',
                borderLeft:
                  item.id === SelectedOptions ? '8px solid #FCD86B' : '',
              }}
              onClick={() => SetSelectedOptions(item.id)}
            >
              <LightTooltip title={item.name} placement='right'>
                <RedHatTypo
                  sx={{
                    py: 1,
                    pl: 1,
                    fontSize: { xs: 9, sm: 11, lg: 12 },
                    fontWeight: 700,
                  }}
                  noWrap
                >
                  {item.name}
                </RedHatTypo>
              </LightTooltip>
            </Box>
          ))}
        </Box>

        <Box sx={{ width: 'inherit', background: '#343434' }}>
          <RedHatTypo
            sx={{
              color: 'white',
              textAlign: 'center',
              fontSize: 12,
              fontWeight: 700,
              py: 1,
            }}
          >
            <DownArrow style={{ marginRight: '4px' }} /> ORDER BOOK
          </RedHatTypo>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '50% 50%',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  pb: 2,
                }}
              >
                <RedHatTypo
                  sx={{
                    fontSize: 12,
                    fontWeight: 700,
                    color: '#BEBEBE',
                    width: '50%',
                    textAlign: 'center',
                  }}
                >
                  VOLUME
                </RedHatTypo>
                <RedHatTypo
                  sx={{
                    fontSize: 12,
                    fontWeight: 700,
                    color: '#BEBEBE',
                    width: '50%',
                    textAlign: 'center',
                  }}
                >
                  BUY PRICE
                </RedHatTypo>
              </Box>
              {BuyPriceDetails.map((item) => (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    py: '6px',
                    background: `linear-gradient(to right ,transparent ${0}% , transparent  ${
                      item.percentage
                    }%,rgba(102, 195, 123,0.4 )  ${
                      item.percentage
                    }%, rgba(102, 195, 123,0.4 )  100%)`,
                  }}
                  key={item.id}
                >
                  <RedHatTypo
                    sx={{
                      fontSize: { xs: 10, sm: 11, md: 12 },
                      fontWeight: 700,
                      color: '#66C37B',
                      width: '50%',
                      textAlign: 'center',
                    }}
                  >
                    {item.volume.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </RedHatTypo>
                  <RedHatTypo
                    sx={{
                      fontSize: { xs: 10, sm: 11, md: 12 },
                      fontWeight: 700,
                      color: '#BEBEBE',
                      width: '50%',
                      textAlign: 'center',
                    }}
                  >
                    {item.buyPrice.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </RedHatTypo>
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                display: 'flex',
                // placeItems: 'flex-start',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  pb: 2,
                }}
              >
                <RedHatTypo
                  sx={{
                    fontSize: 12,
                    fontWeight: 700,
                    color: '#BEBEBE',
                    width: '50%',
                    textAlign: 'center',
                  }}
                >
                  SELL PRICE
                </RedHatTypo>

                <RedHatTypo
                  sx={{
                    fontSize: 12,
                    fontWeight: 700,
                    color: '#BEBEBE',
                    width: '50%',
                    textAlign: 'center',
                  }}
                >
                  VOLUME
                </RedHatTypo>
              </Box>

              {SellPriceDetails.map((item) => (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    py: '6px',
                    background: `linear-gradient(to right ,rgba(246, 104, 94, 0.4) ${
                      item.id * 0
                    }% , rgba(246, 104, 94, 0.4)  ${
                      item.percentage
                    }%,transparent  ${item.percentage}%, transparent 100%)`,
                  }}
                  key={item.id}
                >
                  <RedHatTypo
                    sx={{
                      fontSize: { xs: 10, sm: 11, md: 12 },
                      fontWeight: 700,
                      color: '#F6685E',
                      width: '50%',
                      textAlign: 'center',
                    }}
                  >
                    {item.volume.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </RedHatTypo>
                  <RedHatTypo
                    sx={{
                      fontSize: { xs: 10, sm: 11, md: 12 },
                      fontWeight: 700,
                      color: '#BEBEBE',
                      width: '50%',
                      textAlign: 'center',
                    }}
                  >
                    {item.sellPrice.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </RedHatTypo>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          background: '#343434',
          borderLeft: '2px solid black',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '50% 50%',
            flexGrow: 0,
          }}
        >
          {TwoOptions.map((item) => (
            <RedHatTypo
              sx={{
                fontSize: 13,
                fontWeight: 700,
                color: 'white',
                background:
                  item.id === selectedTwoOptions
                    ? '#343434'
                    : 'rgba(0, 0, 0, 0.8)',
                borderTop:
                  item.id === selectedTwoOptions
                    ? '5px solid #FCD86B'
                    : '5px solid transparent',
                py: 1,
                textAlign: 'center',
                borderTopLeftRadius:
                  item.id === selectedTwoOptions ? '10px' : 0,
                cursor: 'pointer',
              }}
              key={item.id}
              onClick={() => setSelectedTwoOptions(item.id)}
            >
              {item.name}
            </RedHatTypo>
          ))}
        </Box>
        <Box
          sx={{
            py: 1,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around',
            flexDirection: 'column',
            alignItems: 'center',
            height: 'auto',
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '60% 40%',
              width: '95%',
              placeItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                background: '#4F4F4F',
                borderRadius: '10px',
                py: 1,
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ px: 1, borderRight: '1px solid white' }}>
                  <RedHatTypo
                    sx={{
                      fontSize: { xs: 9, md: 10 },
                      fontWeight: 700,
                      color: 'white',
                      textAlign: 'end',
                    }}
                  >
                    AT PRICE
                  </RedHatTypo>
                  <RedHatTypo
                    sx={{
                      fontSize: { xs: 9, md: 10 },
                      fontWeight: 700,
                      color: 'white',
                      textAlign: 'end',
                    }}
                  >
                    SGD
                  </RedHatTypo>
                </Box>

                <RedHatTypo
                  sx={{
                    fontSize: { xs: 11, md: 12 },
                    fontWeight: 700,
                    color: 'white',
                    px: 2,
                  }}
                >
                  86.00
                </RedHatTypo>
              </Box>
              <RedHatTypo
                sx={{
                  fontSize: { xs: 8, md: 10 },
                  fontWeight: 700,
                  color: '#66C37B',
                  justifySelf: 'flex-end',
                  pr: 1,
                }}
              >
                LOWEST PRICE
              </RedHatTypo>
            </Box>

            <TextField
              id='standard-select-currency'
              select
              defaultValue='Limit'
              variant='standard'
              size='small'
              sx={{
                px: 1,
                width: '90%',
                '.MuiInputBase-input': {
                  fontWeight: 600,
                  fontSize: 14,
                  color: 'white',
                },
                '.css-1rgk5nn-MuiInputBase-root-MuiInput-root:before': {
                  borderBottomColor: '#6A6A6A',
                  '&:hover': {
                    borderBottomColor: '#6A6A6A',
                  },
                },
                '.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon': {
                  color: '#6A6A6A',
                },
              }}
            >
              {currencies.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  //   inputProps={{
                  //     style: { color: 'white', fontWeight: 700, fontSize: 12 },
                  //   }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box
            sx={{
              width: '70%',
              display: 'flex',
              background: '#4F4F4F',
              p: 1,
              alignItems: 'center',
              gap: 1,
              borderRadius: '6px',
            }}
          >
            <Box
              sx={{ color: '#CFCFCF', borderRight: '1px solid #BEBEBE', px: 1 }}
            >
              <RedHatTypo sx={{ fontSize: { xs: 11, md: 12 } }}>
                AT PRICE
              </RedHatTypo>
              <RedHatTypo
                sx={{
                  fontSize: { xs: 10, md: 12 },
                  fontWeight: 700,
                  textAlign: 'end',
                }}
              >
                USDT
              </RedHatTypo>
            </Box>
            <RedHatTypo
              sx={{ fontSize: 14, color: '#cfcfcf', fontWeight: 700 }}
            >
              0.02
            </RedHatTypo>
          </Box>

          <Box
            sx={{
              width: '70%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              border: '1px solid #4F4F4F',
              px: 1,
              borderRadius: ' 6px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                background: '#4F4F4F',
                alignItems: 'center',
                borderTopLeftRadius: '6px',
                borderTopRightRadius: '6px',
                gap: 1,
                p: 1,
                width: '100%',
              }}
            >
              <Box
                sx={{
                  color: '#CFCFCF',
                  borderRight: '1px solid #BEBEBE',
                  px: 1,
                }}
              >
                <RedHatTypo sx={{ fontSize: 12 }}>AT PRICE</RedHatTypo>
                <RedHatTypo
                  sx={{ fontSize: 12, fontWeight: 700, textAlign: 'end' }}
                >
                  USDT
                </RedHatTypo>
              </Box>
              <RedHatTypo
                sx={{ fontSize: 14, color: '#cfcfcf', fontWeight: 700 }}
              >
                0.02
              </RedHatTypo>
            </Box>

            <Box
              sx={{
                display: 'flex',
                width: '100%',
                gap: 1,
                px: '2px',
                py: '2px',
                borderBottomLeftRadius: '6px',
                borderBottomRightRadius: '6px',
                alignItems: 'center',
                backgroundColor: 'transparent',
              }}
            >
              <Wallet style={{ height: 15, width: 15 }} />
              <RedHatTypo
                sx={{ fontWeight: 700, fontSize: 14, color: '#CFCFCF' }}
              >
                $1.85
              </RedHatTypo>
            </Box>
          </Box>

          <Button
            variant='contained'
            sx={{ color: '#BB4A2D', fontWeight: 700, borderRadius: '40px' }}
          >
            {selectedTwoOptions === 1 ? 'BUY' : 'SELL'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ExchangeList;
