import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#FCD86B',
    },
    secondary: {
      main: '#fff',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&.Mui-disabled': {
            background:
              'linear-gradient(91.93deg, rgba(196, 167, 79, 0.4) 0%, rgba(187, 74, 45, 0.4) 230.2%)',
            color: '#FFFFFF80',
          },
        },
      },
    },
  },
});
