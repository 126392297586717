import { Box, Button } from '@mui/material';
import React from 'react';
import { RedHatTypo } from '../../StyledComponents/Typographies';
import { ReactComponent as BookIcon } from '../../assets/LandingPage/Book.svg';
import HomeChart from '../../assets/LandingPage/HomeChart.png';
import lines from '../../assets/LandingPage/lines.png';

const LandingPageSection1 = () => {
  return (
    <Box
      sx={{
        width: 'inherit',
        display: 'grid',
        placeItems: 'center',
        backgroundImage: `url(${lines})`,
        backgroundSize: 'contain',
      }}
    >
      <Box
        sx={{
          width: { xs: '90%', md: '80%' },
          display: 'grid',
          gridTemplateColumns: { xs: '100%', sm: '50% 50%' },
          color: 'white',
          py: 1,
          pt: { xs: 4, md: 6 },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Box>
            <RedHatTypo
              sx={{
                fontSize: { xs: 60, md: 100, lg: 150 },
                textShadow: '0 0 5px #fff, 0 0 10px #fff',
                lineHeight: { xs: 'auto', sm: 1 },
              }}
            >
              Crypto
            </RedHatTypo>
            <RedHatTypo
              sx={{
                textShadow: '0 0 5px #fff, 0 0 10px #fff',
                fontSize: { xs: 35, md: 50, lg: 75 },
              }}
            >
              Trading & P2P
            </RedHatTypo>
          </Box>

          <RedHatTypo sx={{ fontSize: { xs: 12, md: 14 } }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit
            amet turpis sed magna malesuada ultricies ac ut ante. Aenean
            bibendum mi eu condimentum posuere. Duis vel accumsan felis, id
            dictum enim. Aenean lacus risus, congue vel varius.
          </RedHatTypo>

          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: 2,
              py: 2,
            }}
          >
            <Button
              sx={{
                fontWeight: { xs: 600, md: 700 },
                borderRadius: '25px',
                color: '#BB4A2D',
                // fontSize: { xs: 12, md: 14 },
              }}
              color='primary'
              variant='contained'
            >
              Get Started
            </Button>
            <Button
              sx={{
                fontWeight: { xs: 600, md: 700 },
                fontSize: { xs: 12, md: 14 },
                borderRadius: '25px',
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
              }}
              color='secondary'
              variant='outlined'
            >
              <BookIcon /> Learn Trading
            </Button>
          </Box>
        </Box>
        <Box sx={{ height: { xs: '100%', sm: '100%' } }}>
          <img
            src={HomeChart}
            alt='homeChart'
            style={{ width: '100%', height: 'inherit', objectFit: 'contain' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default LandingPageSection1;
