import { Box } from '@mui/material';
import React from 'react';
import { RedHatTypo } from '../../StyledComponents/Typographies';
import Recomended_Platform from '../../assets/LandingPage/Recomended_Platform.svg';
import Earn_Income from '../../assets/LandingPage/Earn_Income.svg';
import P2P_Escrow from '../../assets/LandingPage/P2P_Escrow.svg';
import PlayButton from '../../assets/LandingPage/PlayButton.svg';

const Section4_Data = [
  {
    id: 1,
    icon: Earn_Income,
    title: 'Earn income',
    data: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet turpis sed magna malesuada ultricies ac ut ante.',
  },
  {
    id: 2,
    icon: P2P_Escrow,
    title: 'P2P Escrow',
    data: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet turpis sed magna malesuada ultricies ac ut ante.',
  },
  {
    id: 3,
    icon: Recomended_Platform,
    title: 'Recommended Platform',
    data: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet turpis sed magna malesuada ultricies ac ut ante.',
  },
];

const LandingPageSection4 = () => {
  return (
    <Box
      sx={{
        width: '90%',
        color: 'white',
        display: 'grid',
        placeItems: 'center',
        py: { xs: 6, md: 8 },
      }}
    >
      <RedHatTypo
        sx={{
          color: '#FCD535',
          textAlign: 'center',
          fontWeight: 700,
          fontSize: { xs: 20, md: 28, lg: 30 },
        }}
      >
        {/* The CDEX Trade platform */}
        The Trade-Together Trade platform
      </RedHatTypo>
      <RedHatTypo
        sx={{
          width: { xs: '100%', md: '50%' },
          textAlign: 'center',
          fontSize: { xs: 12, md: 14, lg: 16 },
        }}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet
        turpis sed magna malesuada ultricies ac ut ante.
      </RedHatTypo>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '100%',
            sm: '45% 45%',
            lg: '30% 30% 30%',
          },
          width: { xs: '100%', sm: '90%' },
          gap: { xs: 2, sm: 4, lg: 0 },
          justifyContent: 'space-between',
          pt: { xs: 4, md: 8 },
        }}
      >
        {Section4_Data.map((item) => (
          <Box
            key={item.id}
            sx={{
              background:
                'linear-gradient(135deg, #292309 0%, rgba(41, 35, 9, 0.47) 100%)',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: '10px',
              justifyContent: 'space-between',
              pt: 2,
              gap: { xs: 2, md: 0 },
              height: { xs: 'auto', md: '350px' },
            }}
          >
            <Box
              sx={{
                height: { xs: 24, md: 45 },
                width: { xs: 24, md: 45 },
                background:
                  'linear-gradient(135deg, #292309 0%, rgba(41, 35, 9, 0.47) 100%)',
                boxShadow:
                  '2px 4px 4px 0px rgba(255, 255, 255, 0.13) inset, -2px -3px 4px 0px rgba(255, 255, 255, 0.10) inset',
                padding: { xs: '15px', md: '20px' },
                borderRadius: '50%',
                marginLeft: { xs: '10px', md: '25px' },
              }}
            >
              <img
                src={item.icon}
                alt={item.title + '_img'}
                style={{
                  height: 'inherit',
                  width: 'inherit',
                }}
              />
            </Box>

            <RedHatTypo
              sx={{
                color: '#FCD535',
                textAlign: 'center',
                fontWeight: 700,
                fontSize: { xs: 18, md: 28, lg: 30 },
                lineHeight: 1.2,
                width: '80%',
              }}
            >
              {item.title}
            </RedHatTypo>

            <RedHatTypo
              sx={{
                textAlign: 'center',
                fontSize: { xs: 12, md: 14, lg: 16 },
                width: '85%',
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
              sit amet turpis sed magna malesuada ultricies ac ut ante.
            </RedHatTypo>

            <Box
              sx={{
                borderTop: '1px solid rgba(252, 213, 53, 0.20)',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <img
                src={PlayButton}
                alt='playButtonimg'
                style={{ padding: '10px' }}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default LandingPageSection4;
