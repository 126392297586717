import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LandingPage from '../Pages/LandingPage';
import Exchanges from '../Pages/Exchanges';
import SpotTrading from '../Pages/SpotTrading';
import P2P from '../Pages/P2P';
import Learn from '../Pages/Learn';
import About from '../Pages/About';
import Inbox from '../Pages/Inbox';

const RoutesConfig: any = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='/exchange' element={<Exchanges />} />
        <Route path='/spot_trading' element={<SpotTrading />} />
        <Route path='/p2p' element={<P2P />} />
        <Route path='/learn' element={<Learn />} />
        <Route path='/about' element={<About />} />
        <Route path='/inbox' element={<Inbox />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesConfig;
